// 共用http
import { get, post, postUrl, postParams } from './http';

// 无账号登录
export function http_loginNoPwd(userStr) {
    return postUrl('/insurance/api/user/loginNoPwd', userStr);
}

// 获取服务器时间戳
export function http_getServerTime() {
    return get('/insurance/api/common/timestamp');
}

// 登出
export function http_logout() {
    return post('/insurance/api/user/logout');
}

// 查询登录状态
export function http_getLoginStatus() {
    return post('/insurance/api/user/checkLogin');
}

// 根据编码获取数据字典
export function http_getDict(code) {
    return get(`/insurance/api/common/dataDictionaryByCode/${code}`);
}

// 新增部门账户-免登录
export function http_addDepAccount(userId, data) {
    return post(`/insurance/api/user/addDepAccount/${userId}`, data);
}

// 获取用户模板列表-免登录
export function http_getTemplateList(userId) {
    return post(`/insurance/api/userRate/userTemplateList/${userId}`);
}

// 获取用户信息
export function http_getUserInfo() {
    return get('/insurance/api/user/getUserInfo');
}

// 根据姓名和证件号码查询保单信息
export function http_getPFileByassuredInfo(param) {
    return post('/insurance/api/insBillPfile/getPolicyByAssuredInfoPage', param);
}
//根据订单id与被保人id查询订单详情
export function http_getPolicyDetail(data) {
    return get('/insurance/api/insBillPfile/getPolicyDetail', data);
}

/**
 * 可回溯
 * @param {Object} type  Number 回溯类型： 0-投保确认，1-提交订单，2-支付，3-承保，4-发票，5-退保，6-同意条款
 * @param {Object} content String 回溯内容
 * @param {Object} billId String 订单Id  与 proId二选一必填
 * @param {Object} proId String 产品Id  与 billId
 * @returns Promise
 */
export function http_insDatingBack({ type = 0, content, billId, proId }) {
    return post('/insurance/api/insDatingBack/saveInsDatingBack', { type, content, billId, proId });
}

// 支付宝支付订单
export function http_aLiPay(data) {
    return post('/insurance/api/aliPay/billAliPay', data);
}

// 充值支付保支付

export function http_getRechargeForm(data) {
    return post('/insurance/api/AliPayRechargeMoney/getRechargeForm', data);
}

// 获取微信充值订单信息
export function http_getRechargePayInfo(orderId) {
    return postParams('/insurance/api/pay/getRechargeStatus', { orderId });
}

// 翻译姓名
export function http_translateName(namString) {
    return get('insurance/api/common/pinyin/chineseName', { name: namString });
}
// 翻译姓名
export function http_translateNamecps(namString) {
    return get('/insurance/api/common/pinyin/chineseName', { name: namString });
}

// 可回溯功能-保存页面信息
export function http_saveInsTracBack(data) {
    return post(`/insurance/api/insTracBack/saveInsTracBack`, data)
}
//获取系统职业
export function http_getProfessionCodeList() {
    return post('/insurance/api/v2/insure/getProfessionCodeList');
}
/**
 * 根据保司机构code获取省市区
 * @param {string} insurerCode 保司机构code
 * @returns Promise
 */

export function http_getAreaList(insurerCode) {
	return post(`/insurance/api/v2/insure/getInsProductAreaCodeList?insurerCode=${insurerCode}`);
}

//投被保人翻译
export function http_insTranslate(data) {
	return post(`/insurance/api/insTranslate/translate`,data);
}
// 文件组件-下载功能
export function http_insFileDownload(downloadWay,data) {
    return post(`/insurance/api/insFileComponent/insFileDownload/${downloadWay}`,data );
}

// 获取姓名翻译字库
export function http_getInsTranslate() {
    return post('/insurance/api/insTranslate/getInsTranslate')
}

//校验手机号码是否绑定
export function http_checkInsUserOpenIdMobile(mobile) {
    return post(`/insurance/api/insUserOpenId/checkInsUserOpenIdMobile/${mobile}`)
}

//获取短信验证码
export function http_smsCode(data) {
    return post(`/insurance/api/insUserOpenId/smsCode`,data)
}

//微信静默授权
export function http_getCode() {
    return get(`/insurance/api/wx/getCode`)
}

//新增绑定信息
export function http_addInsUserOpenId(data) {
    return post(`/insurance/api/insUserOpenId/addInsUserOpenId`,data)
}

//获取公众号的appid
export function http_getInsUserOpenIdAppId() {
    return get(`/insurance/api/insUserOpenId/getInsUserOpenIdAppId`)
}

export function http_getOpenId(code) {
	return get(`/insurance/api/insUserOpenId/getOpenId`, { code });
}

//根据openid校验是否绑定
export function http_checkInsUserOpenIdByOpenId(openId) {
    return post(`/insurance/api/insUserOpenId/checkInsUserOpenIdByOpenId/${openId}`)
}

//解绑
export function http_deleteInsUserOpenIdByOpenId(openId) {
    return post(`/insurance/api/insUserOpenId/deleteInsUserOpenIdByOpenId/${openId}`)
}

//校验编码是否有效
export function http_checkCodeIsValid(lpId,code) {
    return post(`/insurance/api/autoPayCode/checkCodeIsValid/${lpId}/${code}`)
}