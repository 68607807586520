// 生僻字相关功能

import { http_getInsTranslate } from "../../request/common"

// 获取生僻字并存储到缓存中
async function getStr() {
    if(location.pathname=='/bindPhone'){
        return
    }
    let res = await http_getInsTranslate()
    let obscureWordList = res.filter(item => item.type === 2)
    let obscureWordStr = obscureWordList.map(item => item.text).join("")
    localStorage.setItem('obscureWordString', obscureWordStr)
}
getStr()

/**
 * 获取生僻字
 * @returns String
 */
export function getObscureWordString() {
    let str = localStorage.getItem('obscureWordString')
    if (str) {
        return str
    } else {
        return ""
    }
}

/**
 * 获取检测生僻字的正则，至少存在一个生僻字
 * @returns RegExp对象
 */
export function getObscureWordStringRegExp() {
    let str = getObscureWordString()
    return new RegExp(`[${str}]{1,}`)
}

// 生僻字
export const obscureWordString = getObscureWordString()

// 检测生僻字的正则
export const obscureWordStringRegExp = getObscureWordStringRegExp()